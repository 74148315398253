<template>
    <div class="service-details-wrapper">
        <div class="banner-img-wrapper">
            <h2 class="cover-title" style="font-size: 25px">{{ Object.keys($route.params).length > 0 ? checkServiceName() : $t('portal.circular') + ' ' + $t('globalTrans.list') }}</h2>
            <img src="@/assets/images/cover-img.jpg" alt="">
        </div>
        <!-- Breadcraumb  -->
        <div>
            <b-container>
                <ol class="breadcrumb custom-bread">
                    <li class="breadcrumb-item"><router-link to="/portal/home" target="_self" class="">{{ $t('portal.home') }}</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/portal/all-services" target="_self" class="">{{ $t('portal.all_service') }}</router-link></li>
                    <li @click="$router.go(-1)" class="breadcrumb-item"><router-link to="" target="_self">{{ $t('portal.service_details') }}</router-link></li>
                    <li class="breadcrumb-item active"><span aria-current="location">{{ $t('portal.circular') }}</span></li>
                </ol>
            </b-container>
        </div>
        <div class="service-description">
          <b-container>
            <body-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('exportTrophyCircular.circular') }} {{ $t('globalTrans.search') }}</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                    >
                    <template v-slot:label>
                      {{$t('globalTrans.fiscal_year')}}
                    </template>
                      <v-select name="fiscal_year_id"
                        v-model="search.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_iabm.nominated_organization_type')"
                        label-for="organization"
                    >
                      <v-select name="organization"
                        v-model="search.org_type_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgTypeList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_iabm.nominated_organization')"
                        label-for="organization"
                    >
                      <v-select name="organization"
                        v-model="search.org_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-button size="sm" variant="primary" @click="searchData">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <!-- search section end -->
            </body-card>

            <body-card>
              <!-- table section start -->
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('exportTrophyCircular.circular') }} {{ $t('globalTrans.list') }}</h4>
              </template>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row>
                    <b-col sm="12">
                      <div class="quick-filter-wrapper">
                        <div class="quick-filter-left">
                          <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                            <b-dropdown-form>
                              <div
                                class="form-group"
                                v-for="(field, index) in labelData"
                                :key="index"
                                >
                                  <b-form-checkbox
                                    :id="'checkbox-' + field.label_en"
                                    v-model="field.show"
                                    :name="'checkbox-' + field.label_en"
                                    value=1
                                    unchecked-value=0
                                  >
                                    {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                                  </b-form-checkbox>
                              </div>
                            </b-dropdown-form>
                          </b-dropdown>
                        </div>
                        <div class="quick-filter-right">
                          <b-form-group
                            :label="$t('menu.perpage')"
                            label-for="per-page-select"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="search.limit"
                              :options="pageOptions"
                              size="sm"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12" class="table-responsive">
                      <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                        <template v-slot:cell(serial)="data">
                          {{ $n(data.item.serial + pagination.slOffset) }}
                        </template>
                        <template v-slot:cell(application_deadline)="data">
                          <span>{{ data.item.application_deadline | dateFormat }}</span>
                        </template>
                        <template v-slot:cell(training_duration)="data">
                          {{ data.item.training_start_date | dateFormat }} {{ $t('elearning_tim.to') }} {{ data.item.training_end_date | dateFormat }}
                        </template>
                        <template v-slot:cell(org_type_id)="data">
                          {{ getOrgType(data.item.org_type_id) }}
                        </template>
                        <template v-slot:cell(training_title_id)="data">
                          {{ getTitle(data.item.training_title_id) }}
                        </template>
                        <template v-slot:cell(action)="data">
                          <!-- <b-button v-b-modal.modal-form-details variant="iq-bg-success" size="sm" class="btn mb-1 btn-primary" @click="details(data.item)" :title="$t('globalTrans.view')">{{ $t('globalTrans.view') }}</b-button> -->
                          <b-button v-b-modal.modal-5 variant="iq-bg-success mr-1" size="sm" @click="detailData(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>
                          <b-button type="button" class="btn mb-1 btn-success" variant="iq-bg-success ml-1" size="sm" @click="setServiceUrl(data.item)" :title="$t('globalTrans.apply')">{{ $t('globalTrans.apply') }}</b-button>
                          <!-- <b-button v-if="data.item.deadline >= current_date" type="button" class="btn mb-1 btn-success" variant="iq-bg-success ml-1" size="sm" @click="setServiceUrl(data.item)" :title="$t('globalTrans.apply')">{{ $t('globalTrans.apply') }}</b-button> -->
                        </template>
                      </b-table>
                      <div class="pagination-wrapper mt-3">
                        <b-pagination
                          v-model="pagination.currentPage"
                          :perPage="search.limit"
                          :total-rows="pagination.totalRows"
                          @input="searchData"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-overlay>
              </template>
            </body-card>
          </b-container>
        </div>
        <!-- <b-modal id="modal-form-details" size="lg" :title="detailsTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title>
            {{ $t('exportTrophyCircular.circular') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <Details :id="editItemId" :item="item" :key="editItemId" ref="details"/>
        </b-modal> -->
        <b-modal id="modal-5" size="lg" hide-footer :title="$t('elearning_iabm.circular_info')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="item" :storDataItem="storDataItem" :key="id" ref="details"/>
        </b-modal>
    </div>
</template>
<script>
import Details from './Details.vue'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/list'
import i18n from '@/i18n'

const defaultColumn = [
  { label_en: i18n.messages.en.globalTrans.sl_no, label_bn: i18n.messages.bn.globalTrans.sl_no, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
  { label_en: i18n.messages.en.tradeFairInfoManagement.circular_memo_no, label_bn: i18n.messages.bn.tradeFairInfoManagement.circular_memo_no, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.globalTrans.year, label_bn: i18n.messages.bn.globalTrans.year, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.elearning_iabm.nominated_organization_type, label_bn: i18n.messages.bn.elearning_iabm.nominated_organization_type, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.elearning_config.training_title, label_bn: i18n.messages.bn.elearning_config.training_title, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '28%' } },
  { label_en: i18n.messages.en.tradeFairInfoManagement.submission_deadline, label_bn: i18n.messages.bn.tradeFairInfoManagement.submission_deadline, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.elearning_iabm.training_duration, label_bn: i18n.messages.bn.elearning_iabm.training_duration, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.elearning_iabm.target_audience, label_bn: i18n.messages.bn.elearning_iabm.target_audience, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.globalTrans.action, label_bn: i18n.messages.bn.globalTrans.action, class: 'text-center', show: '1', order: 9, thStyle: { width: '20%' } }
]
const today = new Date().toISOString().substr(0, 10)
export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      current_date: today,
      sortBy: '',
      search: {
        fiscal_year_id: 0,
        limit: 20
      },
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      },
      userDetails: [],
      sortDesc: true,
      sortDirection: 'desc',
      labelData: [],
      item: [],
      storData: [],
      storDataItem: [],
      orgList: [],
      editItemId: '',
      service_name: ''
    }
  },
  components: {
    Details
  },
  created () {
    this.search = Object.assign({}, this.search, {
      fiscal_year_id: this.$store.state.currentFiscalYearId
    })
    window.scrollTo(0, 0)
    this.labelData = this.labelList
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.org_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrgData(newVal)
      }
    }
  },
  computed: {
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
    },
    currentLocale () {
      return this.$i18n.locale
    },
    labelList: function () {
      const dataList = defaultColumn
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
          const data = { value: item.label_en, text: item.label_bn }
          return Object.assign({}, item, data)
        } else {
          const data = { value: item.label_en, text: item.label_en }
          return Object.assign({}, item, data)
        }
      })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        const labelData = {}
        labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
        return Object.assign(item, labelData)
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'fiscal_year_bn' },
          { key: 'org_type_id' },
          { key: 'training_title_id' },
          { key: 'application_deadline' },
          { key: 'training_duration' },
          { key: 'target_audience_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'fiscal_year' },
          { key: 'org_type_id' },
          { key: 'training_title_id' },
          { key: 'application_deadline' },
          { key: 'training_duration' },
          { key: 'target_audience' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    detailsTitle () {
      return this.$t('exportTrophyCircular.circular') + ' ' + this.$t('globalTrans.details')
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    fiscalYearList () {
      return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  methods: {
    detailData (item) {
      this.item = item
      this.storDataItem = this.storData
    },
    getOrgType (orgIds) {
      if (orgIds !== null) {
        const arr = []
        orgIds.forEach(item => {
        const orgId = this.orgTypeList.find(obj => obj.value === item)
          if (orgId !== undefined) {
            const orgName = this.$i18n.locale === 'en' ? ' ' + orgId.text_en : ' ' + orgId.text_bn
            arr.push(orgName)
          }
        })
        return arr.toString()
      }
    },
    getTitle (id) {
      const titleId = this.storData.trainingTitleList.find(tmp => tmp.value === id)
      if (titleId !== undefined) {
        return this.$i18n.locale === 'en' ? titleId.text_en : titleId.text_bn
      }
    },
    async getOrgData (orgTypeId) {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/portal/circular-publication/nominated-organization', null)
      if (result.success) {
        const titleId = result.data.filter(tmp => tmp.org_type_id === orgTypeId)
        if (titleId !== undefined) {
          this.orgList = titleId.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.org_name_bn }
            } else {
              return { value: obj.id, text: obj.org_name_en }
            }
          })
        } else {
          this.orgList = []
        }
      }
    },
    checkServiceName () {
      return this.currentLocale === 'bn' ? this.$route.params.name_bn : this.$route.params.name
    },
    searchData () {
      this.loadData()
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/portal/circular-publication/list', params)
      if (result.success) {
        // this.userList = result.userDetails
        const listData = result.data.data.map((item, index) => {
          const fiscalYearObj = this.$store.state.Portal.commonObj.fiscalYearList.find(tmp => tmp.value === item.fiscal_year_id)

          const fiscalYearData = {
            fiscal_year_bn: fiscalYearObj !== undefined ? fiscalYearObj.text_bn : '',
            fiscal_year: fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
          }
          return Object.assign({}, item, { serial: index }, fiscalYearData)
        })
        this.storData = result.storData
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getFiscalYear (fiscalYear) {
      const fiscalYearObj = this.$store.state.Portal.commonObj.fiscalYearList.find(fiscal => fiscal.value === parseInt(fiscalYear))
      if (fiscalYearObj !== 'undefined') {
        return this.currentLocale === 'bn' ? fiscalYearObj.text_bn : fiscalYearObj.text_en
      }
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    setServiceUrl (item) {
      this.$store.dispatch('Portal/setServiceUrl', {
        externalPanelServiceUrl: '/training-panel/circular-list',
        externalPanelCurrentServiceUrl: '/training-panel/circular-list'
      })
      this.$router.push('/auth/login').catch(() => {})
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
<style>
  .apply_btn{
    font-size: 13px;
    padding: 3px 5px;
  }
  .modal-title {
    flex-grow: 1 !important;
  }
</style>

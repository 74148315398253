<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
           <body-card>
            <template>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="10">
                            <div class="titleDiv text-left">
                                <span class="text-left text-dark reportTitle" style="font-weight:bold;">{{ $t('elearning_iabm.circular_info') }}</span><br>
                            </div>
                        </list-report-head>
                    </b-col>
                    </b-row>
                    <b-col lg="12">
                        <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                            <b-table-simple bordered>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('globalTrans.fiscal_year') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getFiscalYear(circular.fiscal_year_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.circular_memo_no }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.nominated_organization_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getOrgTypeList(circular.org_type_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.nominated_organization') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getOrgList(circular.nominated_org_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.training_provider_organization') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getOrg(circular.org_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.training_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getType(circular.training_type_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.training_title') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getTitle(circular.training_title_id) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('teaGardenPduService.application_deadline') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.application_deadline | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.training_start_date') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.training_start_date | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.training_end_date') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.training_end_date | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('ditfTradeFairManages.circular_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getCircularTypeList(circular.circular_type) }}</b-td>
                                </b-tr>
                                <b-tr v-if="circular.circular_type === 1">
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.registration_fee') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ $n(circular.registration_fee) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('globalTrans.description') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ $i18n.locale === 'en' ? circular.description : circular.description_bn }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.target_audience') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ $i18n.locale === 'en' ? circular.target_audience : circular.target_audience_bn }}</b-td>
                                </b-tr>
                            </b-table-simple>
                            <div class="text-left m-3" v-if="circular.upload_guidelines.length">
                                  <span class="text-left text-dark" style="font-weight:bold; border-bottom: 1px solid rgb(133, 126, 126);">{{ $t('globalTrans.document') }} {{ $t('globalTrans.info') }}</span><br>
                            </div>
                            <b-table-simple bordered v-if="circular.upload_guidelines.length">
                              <thead>
                                <tr>
                                  <th style="width:20%">{{ $t('globalTrans.sl_no') }}</th>
                                  <th style="width:40%">{{ $t('globalTrans.document') }} {{ $t('globalTrans.name') }}</th>
                                  <th style="width:40%">{{ $t('elearning_iabm.upload_guidelines') }}</th>
                                </tr>
                              </thead>
                              <b-tbody>
                                <b-tr v-for="(item, index) in circular.upload_guidelines" :key="index">
                                    <b-td>
                                      <slot v-if="item.attachment_name">{{ $n(index + 1) }}</slot>
                                    </b-td>
                                    <b-td>
                                      {{ item.attachment_name }}
                                    </b-td>
                                    <b-td>
                                      <a v-if="item.attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/' + item.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                    </b-td>
                                </b-tr>
                              </b-tbody>
                            </b-table-simple>
                        </b-col>
                        </b-row>
                    </b-col>
              </b-overlay>
            </template>
          </body-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 15px;
}
</style>
<script>
// import ExportPdf from './export_pdf_details'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    name: 'FormLayout',
    components: {
      ListReportHead
    },
    props: ['item', 'storDataItem'],
    created () {
      this.circular = this.item
    },
    data () {
        return {
          circular: [],
          slOffset: 1,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          orgTypeList: [
            {
              value: 1,
              text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
              text_en: 'MOC',
              text_bn: 'এমওসি'
            },
            {
              value: 2,
              text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
              text_en: 'Private',
              text_bn: 'ব্যক্তিগত'
            }
          ],
          circularType: [
            {
              value: 1,
              text: 'With Fee',
              text_en: 'With Fee',
              text_bn: 'ফি সহ'
            },
            {
              value: 2,
              text: 'Without Fee',
              text_en: 'Without Fee',
              text_bn: 'ফি ছাড়া'
            }
          ]
        }
    },
    computed: {
        loading () {
        return this.$store.state.commonObj.loading
      },
      fiscalYearList: function () {
        return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 1)
      }
    },
    methods: {
      getOrgTypeList (orgTypeIds) {
        if (orgTypeIds !== null) {
          const arr = []
          if (orgTypeIds.constructor === Array) {
            orgTypeIds.forEach(item => {
            const orgTypeId = this.orgTypeList.find(obj => obj.value === item)
            const orgTypeName = this.$i18n.locale === 'en' ? ' ' + orgTypeId.text_en : ' ' + orgTypeId.text_bn
            arr.push(orgTypeName)
            })
          }
          return arr.toString()
        }
      },
      getOrgList (orgName) {
        if (orgName !== null) {
          const arr = []
          if (orgName.constructor === Array) {
            orgName.forEach(item => {
            const orgId = this.storDataItem.nominatedOrgList.find(obj => obj.value === item)
            const orgName = this.$i18n.locale === 'en' ? ' ' + orgId.text_en : ' ' + orgId.text_bn
            arr.push(orgName)
            })
          }
          return arr.toString()
        }
      },
      getTitle (id) {
        const titleId = this.storDataItem.trainingTitleList.find(tmp => tmp.value === id)
        if (titleId !== undefined) {
          return this.$i18n.locale === 'en' ? titleId.text_en : titleId.text_bn
        }
      },
      getOrg (id) {
        const titleId = this.$store.state.Portal.commonObj.orgProfileList.find(tmp => tmp.value === id)
        if (titleId !== undefined) {
          return this.$i18n.locale === 'en' ? titleId.text_en : titleId.text_bn
        }
      },
      getType (id) {
        const titleId = this.storDataItem.trainingTypeList.find(tmp => tmp.value === id)
        if (titleId !== undefined) {
          return this.$i18n.locale === 'en' ? titleId.text_en : titleId.text_bn
        }
      },
      getFiscalYear (id) {
        const orgId = this.fiscalYearList.find(obj => obj.value === id)
          return this.$i18n.locale === 'en' ? ' ' + orgId.text_en : ' ' + orgId.text_bn
      },
      getCircularTypeList (circularType) {
        const orgId = this.circularType.find(obj => obj.value === circularType)
        return this.$i18n.locale === 'en' ? ' ' + orgId.text_en : ' ' + orgId.text_bn
      }
      // pdfExport () {
      //   const reportTitle = this.$t('elearning_iabm.circular_info')
      //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.circular, this)
      // }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  /* .reportTitle {
    font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
  } */
  /* .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  } */
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
